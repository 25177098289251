import {
	type ApplicationConfig,
	inject,
	provideAppInitializer,
	provideExperimentalZonelessChangeDetection,
} from "@angular/core";
import { provideRouter } from "@angular/router";

import { provideHttpClient, withInterceptors } from "@angular/common/http";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import { AuthService } from "@auth/services/auth.service";
import { mockInterceptor } from "@common/interceptors/mock.interceptor";
import { appInit } from "./app.initializer";
import { routes } from "./app.routes";

export const appConfig: ApplicationConfig = {
	providers: [
		provideExperimentalZonelessChangeDetection(),
		provideRouter(routes),
		provideAnimationsAsync(),
		provideHttpClient(withInterceptors([mockInterceptor])),
		provideAppInitializer(() => appInit(inject(AuthService))),
	],
};
