<mat-toolbar>
	<span>Portál člena</span>

	@if(authService.$isLogged()) {
		<mat-divider [vertical]="true"></mat-divider>

		@for(menuItem of menuItems | keyvalue; track menuItem.key) {
			<button mat-button [routerLink]="menuItem.key">
				<mat-icon>{{ menuItem.value?.icon }}</mat-icon>
				{{ menuItem.value?.name }}
			</button>
		}

		<div style="margin-left: auto; display: flex; justify-content: space-around">
			<button mat-raised-button [routerLink]="ERoute.USER">

				<mat-icon>person</mat-icon>
				{{authService.$user()?.public_name }}
			</button>

			<button mat-raised-button (click)="authService.logout()">
				<mat-icon>logout</mat-icon>
			</button>
		</div>
	}
</mat-toolbar>
