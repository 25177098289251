import type { Routes } from "@angular/router";
import { authGuard } from "@auth/guards/auth.guard";
import { ERoute } from "@common/types/ERoute";

export const routes: Routes = [
	{
		path: ERoute.LOGIN,
		loadComponent: () =>
			import("./auth/components/login/login.component").then(
				(m) => m.LoginComponent,
			),
	},
	{
		path: "",
		canActivate: [authGuard],
		children: [
			{
				path: ERoute.DASHBOARD,
				loadComponent: () =>
					import("./dashboard/dashboard.component").then(
						(m) => m.DashboardComponent,
					),
			},

			{
				path: ERoute.USER,
				loadComponent: () =>
					import("./user/user.component").then((m) => m.UserComponent),
				canActivate: [authGuard],
			},
		],
	},
	{ path: "**", redirectTo: "/dashboard" },
];
