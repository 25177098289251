import { type HttpInterceptorFn, HttpResponse } from "@angular/common/http";
import { of } from "rxjs";

const mockData: Record<string, unknown> = {
	"/api/auth/credentials":
		"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzaG93bl9uYW1lIjoiRHVtbXkgU2hvd24gTmFtZSIsIm1lbWJlcl9udW1iZXIiOjEyMywiZXhwIjoxNzMxODE1ODYzLCJpYXQiOjE3MzE2NDMwNjMsImV4cF9pc284NjAxIjoiMjAyNC0xMS0xN1QwMzo1Nzo0My43MzQ5MjkrMDA6MDAifQ.vZT-tyqMDeh5gVpi8u6YxAQ3BTkEyv90icDIeluI4iw",
	"/api/auth/me": {
		id_user: "3fa85f64-5717-4562-b3fc-2c963f66afa6",
		member_number: 1,
		public_name: "John Doe",
		personal_data: {
			civic_name: "Honza Srna",
		},
	},
	"/api/money/balance": 1000,
};

export const mockInterceptor: HttpInterceptorFn = (req, next) => {
	const { url } = req;

	if (mockData[url]) {
		return of(new HttpResponse({ status: 200, body: mockData[url] }));
	}

	return next(req);
};
