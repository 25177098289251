import { KeyValuePipe } from "@angular/common";
import { Component, inject } from "@angular/core";
import { MatButton } from "@angular/material/button";
import { MatDivider } from "@angular/material/divider";
import { MatIcon } from "@angular/material/icon";
import { MatToolbar } from "@angular/material/toolbar";
import { RouterLink } from "@angular/router";
import { AuthService } from "@auth/services/auth.service";
import { ERoute } from "@common/types/ERoute";

@Component({
	selector: "app-toolbar",
	standalone: true,
	imports: [
		MatIcon,
		MatButton,
		MatToolbar,
		KeyValuePipe,
		MatDivider,
		RouterLink,
	],
	templateUrl: "./toolbar.component.html",
	styleUrl: "./toolbar.component.scss",
})
export class ToolbarComponent {
	protected authService = inject(AuthService);

	protected readonly ERoute = ERoute;
	protected menuItems: Partial<Record<ERoute, { name: string; icon: string }>> =
		{
			[ERoute.DASHBOARD]: { name: "Dashboard", icon: "space_dashboard" },
		};
}
