import { Component, effect, inject } from "@angular/core";
import { Router, RouterOutlet } from "@angular/router";
import { AuthService } from "@auth/services/auth.service";
import { ERoute } from "@common/types/ERoute";
import { ToolbarComponent } from "./layout/components/toolbar/toolbar.component";

@Component({
	selector: "app-root",
	standalone: true,
	imports: [RouterOutlet, ToolbarComponent],
	templateUrl: "./app.component.html",
	styleUrl: "./app.component.scss",
})
export class AppComponent {
	private authService = inject(AuthService);
	private router = inject(Router);

	constructor() {
		effect(() => {
			if (!this.authService.$isLogged()) {
				this.router.navigate([ERoute.LOGIN]);
			}
		});
	}
}
